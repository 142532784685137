export function Footer() {
	return (
		<>
			<div className="ship-footer-spacer"></div>
			<footer className="noprint">
				<p>Created by Mark Alliban</p>
				<a href="https://forum.mongoosepublishing.com/members/burger.4574/">Message me on the Mongoose forums</a>
			</footer>
		</>
	)

}
